import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa'; // Impor ikon "X" dari react-icons/fa
import { format } from 'date-fns';
import './EMR.css';

const EMR = () => {
    const { id } = useParams();

    const [patientDetails, setPatientDetails] = useState(null);
    const [treatments, setTreatments] = useState([]);
    const [allergies, setAllergies] = useState('');
    const [healthHistory, setHealthHistory] = useState('');
    const [zoomedImage, setZoomedImage] = useState(null);

    useEffect(() => {
        // Mengambil data pasien
        axios.get(`https://my-rekam-medis-default-rtdb.firebaseio.com/patients/${id}.json`)
            .then(response => {
                setPatientDetails(response.data);

                // Mengambil data alergi dan riwayat kesehatan dari Firebase (jika ada)
                if (response.data) {
                    setAllergies(response.data.Allergies || '');
                    setHealthHistory(response.data.HealthHistory || '');
                }
            })
            .catch(error => {
                console.error('Terjadi kesalahan:', error);
            });

        // Mengambil data riwayat pengobatan
        axios.get(`https://my-rekam-medis-default-rtdb.firebaseio.com/patients/${id}/medical_records.json`)
            .then(response => {
                const treatmentsArray = Object.keys(response.data).map(recordId => ({
                    id: recordId,
                    ...response.data[recordId],
                }));
                setTreatments(treatmentsArray);
            })
            .catch(error => {
                console.error('Terjadi kesalahan:', error);
            });
    }, [id]);

    const handleAllergiesChange = (e) => {
        setAllergies(e.target.value);
    };

    const handleHealthHistoryChange = (e) => {
        setHealthHistory(e.target.value);
    };

    const saveAllergiesAndHealthHistory = () => {
        // Simpan data alergi dan riwayat kesehatan ke Firebase sesuai dengan ID pasien
        axios.put(`https://my-rekam-medis-default-rtdb.firebaseio.com/patients/${id}.json`, {
            ...patientDetails, // Menyertakan data pasien yang sudah ada
            Allergies: allergies,
            HealthHistory: healthHistory
        })
            .then(response => {
                console.log('Data alergi dan riwayat kesehatan berhasil disimpan');
            })
            .catch(error => {
                console.error('Terjadi kesalahan:', error);
            });
    };

    const deleteAllergies = () => {
        // Hapus data alergi dari Firebase sesuai dengan ID pasien
        axios.put(`https://my-rekam-medis-default-rtdb.firebaseio.com/patients/${id}.json`, {
            ...patientDetails, // Menyertakan data pasien yang sudah ada
            Allergies: '',
            HealthHistory: healthHistory
        })
            .then(response => {
                console.log('Data alergi berhasil dihapus');
                setAllergies('');
            })
            .catch(error => {
                console.error('Terjadi kesalahan:', error);
            });
    };

    const deleteHealthHistory = () => {
        // Hapus data riwayat kesehatan dari Firebase sesuai dengan ID pasien
        axios.put(`https://my-rekam-medis-default-rtdb.firebaseio.com/patients/${id}.json`, {
            ...patientDetails, // Menyertakan data pasien yang sudah ada
            Allergies: allergies,
            HealthHistory: ''
        })
            .then(response => {
                console.log('Riwayat kesehatan berhasil dihapus');
                setHealthHistory('');
            })
            .catch(error => {
                console.error('Terjadi kesalahan:', error);
            });
    };

    const confirmDelete = (treatmentId) => {
        const isConfirmed = window.confirm("Apakah Anda yakin akan menghapus data ini?");
        if (isConfirmed) {
            deleteTreatmentRecord(treatmentId);
        }
    };


    const deleteTreatmentRecord = (treatmentId) => {
        // Hapus data riwayat pengobatan dari Firebase sesuai dengan ID treatment
        axios.delete(`https://my-rekam-medis-default-rtdb.firebaseio.com/patients/${id}/medical_records/${treatmentId}.json`)
            .then(response => {
                console.log('Riwayat pengobatan berhasil dihapus');
                // Refresh data riwayat pengobatan setelah penghapusan
                const updatedTreatments = treatments.filter(treatment => treatment.id !== treatmentId);
                setTreatments(updatedTreatments);
            })
            .catch(error => {
                console.error('Terjadi kesalahan:', error);
            });
    };


    return (
        <div>
            <Link to="/home" className="home-link">
                <img src='https://firebasestorage.googleapis.com/v0/b/rekammedis-70985.appspot.com/o/images__14_-removebg-preview.png?alt=media&token=dac5fd74-4670-4ce9-a490-4f01637c2f22' />
            </Link>


            <h2>Electronic Medical Records (EMR)</h2>
            <div className="emr-container">
                <div className="patient-details">
                    {patientDetails && (
                        <div>
                            <h4>Biodata Pasien</h4>
                            <p>Nama: {patientDetails.nama}</p>
                            <p>Tanggal Lahir: {patientDetails.tanggal_lahir}</p>
                            <div>
                                <h5>
                                    <span className="allergies-label">Alergi</span>
                                    {allergies && (
                                        <span className="delete-icon-wrapper">
                                            <FaTimes onClick={deleteAllergies} className="delete-icon" color="white" />
                                        </span>
                                    )}
                                </h5>
                                {allergies ? (
                                    <div>
                                        <p>{allergies}</p>
                                    </div>
                                ) : (
                                    <p>Tidak ada alergi</p>
                                )}
                            </div>

                            <div>
                                <h5>
                                    <span className="health-history-label">Riwayat Kesehatan</span>
                                    {healthHistory && (
                                        <span className="delete-icon-wrapper">
                                            <FaTimes onClick={deleteHealthHistory} className="delete-icon" color="white" />
                                        </span>
                                    )}
                                </h5>
                                {healthHistory ? (
                                    <div>
                                        <p>{healthHistory}</p>
                                    </div>
                                ) : (
                                    <p>Tidak ada riwayat kesehatan</p>
                                )}
                            </div>

                            <div>
                                <h4 className="ubah-text">Ubah Alergi</h4>
                                <input className="my-input" type="text" value={allergies} onChange={handleAllergiesChange} />
                            </div>
                            <div>
                                <h4 className="ubah-text">Ubah Riwayat Kesehatan</h4>
                                <input className="my-input" type="text" value={healthHistory} onChange={handleHealthHistoryChange} />
                            </div>

                            <button className="button-save" onClick={saveAllergiesAndHealthHistory}>
                                Simpan
                            </button>

                        </div>
                    )}
                </div>

                <div className="treatments">
                    <Link to={`/emr/${id}/tambah-pengobatan`}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/rekammedis-70985.appspot.com/o/ButtonBerobat.png?alt=media&token=5f8a6c51-c2e7-44d0-9609-d5e521aa9a13" alt="Tambah Pengobatan" />
                    </Link>
                    <h3>Riwayat Pengobatan</h3>
                    {treatments
                        .slice()
                        .sort((a, b) => b.timestamp - a.timestamp)
                        .map(treatment => (
                            <div className="treatment-card" key={treatment.id}>
                                {/* Tambahkan gambar sampah */}
                                <img
                                    src="/trash.png" // Ganti dengan path menuju gambar sampah
                                    alt="Delete"
                                    className="delete-treatment-icon" // Ganti dengan nama kelas yang unik
                                    style={{ maxWidth: '80%', height: 'auto' }}
                                    onClick={() => confirmDelete(treatment.id)}
                                />

                                <p>Tanggal dan Waktu: {format(new Date(treatment.timestamp), 'dd MMMM yyyy HH:mm:ss')}</p>
                                <p>Keluhan: {treatment.keluhan}</p>
                                <p>Pemeriksaan Fisik: {treatment.pemeriksaan_fisik}</p>
                                <p>Tanda-Tanda Vital: {treatment.tanda_vital}</p>
                                <p>Terapi Obat: {treatment.terapi_obat}</p>
                                <p>Diagnosa Medis: {treatment.diagnosa_medis}</p>
                                {treatment.images && treatment.images.length > 0 && (
                                    <img
                                        src={treatment.images[0]} // Ganti dengan sumber gambar dari treatment
                                        alt={`Treatment ${treatment.id}`}
                                        className="treatment-image"
                                        onClick={() => setZoomedImage(treatment.images[0])}
                                    />
                                )}
                            </div>
                        ))}
                </div>

                {zoomedImage && (
                    <div className="zoom-modal" onClick={() => setZoomedImage(null)}>
                        <img src={zoomedImage} alt="Zoomed Image" className="zoomed-image" />
                    </div>
                )}

            </div>
        </div>
    );
};

export default EMR;
